.side-panel {
  position: fixed;
  top: 0;
  width: 500px;
  height: 100vh;
  overflow-y: auto;
  background: white;
  box-shadow: -2px 0 10px rgba(0, 0, 0, 0.2);
  transition: right 0.3s ease-in-out;
  z-index: 1000;
}

.side-panel.right {
  right: -550px;
}

.side-panel.right.open {
  right: 0;
  padding-bottom: 40px;
}

.close-btn {
  border: none;
  background: none;
  cursor: pointer;
  padding: 0;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  z-index: 999;
}

.headerContainer {
  display: flex;
  flex-direction: row;
  gap: 20px;
  padding-left: 40px;
  padding-top: 40px;
}

.headerText {
  font-size: 15px;
  color: #636363;
  align-items: center;
}

.divider {
  margin-left: 10px;
  margin-right: 10px;
  border-bottom: 1px solid #a2a2a2;
}

.metricBox {
  border: 1px solid #a2a2a2;
  border-radius: 6px;
  margin-top: 40px;
  margin-left: 40px;
  margin-right: 40px;
  padding-left: 10px;
  padding-right: 10px;
}

.metricTableHead {
  display: flex;
  justify-content: space-between;
  min-height: 50px;
  align-items: center;
}

.tableHeaderText {
  font-family: Montserrat;
  font-weight: 400;
  font-size: 14px;
  line-height: 17.07px;
  letter-spacing: 0%;
  text-align: center;
  color: #636363;
  width: 154px;
}

.tableRows {
  display: flex;
  justify-content: space-between;
  min-height: 50px;
  align-items: center;
}

.tableRowText {
  font-family: Montserrat;
  font-weight: 500;
  font-size: 14px;
  line-height: 17.07px;
  letter-spacing: 0%;
  text-align: center;
  color: #000000;
  width: 126px;
}
.infoIcon {
  margin-top: 2px;
  color: #222;
  border-radius: 50%;
  border: 2.5px solid #222;
  width: 14px;
  height: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Montserrat;
  font-size: 10px;
  font-weight: 600;

  cursor: pointer;
  position: relative;
}
.graphHeader {
  margin-top: 40px;
  margin-left: 40px;
  margin-right: 40px;
  font-family: Montserrat;
  font-weight: 500;
  font-size: 15px;
  line-height: 18.29px;
  letter-spacing: 0%;
  color: #636363;
}

.graphDesc {
  font-family: Montserrat;
  font-weight: 400;
  font-size: 12px;
  line-height: 14.63px;
  letter-spacing: 0%;
  color: #3e3e3e;
  margin-top: 10px;
  margin-left: 40px;
  margin-right: 40px;
}

.graphContainer {
  flex: 1;
  border-radius: 15px;
  border: 1px solid #d9d9d9;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  margin-top: 10px;
  padding: 10px;
  margin-left: 40px;
  margin-right: 40px;
}

.loader {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 10;
  top: 0px;
  left: 0px;
  background: rgba(0, 0, 0, 0.1);

  & > div {
    position: fixed;
    top: 50%;
    left: 50%;
  }

  & svg {
    transform: scale(3);
  }
}
